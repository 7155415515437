import React from 'react';
import Modal from "react-bootstrap/Modal";
import "../style.css";

export default function CommonModal(props) {
    // const [modalOpen, setModalOpen] = React.useState(false);
    const { headerTitle, body, footer } = props
    return (
        <>
            <Modal
                {...props}
                // size="md"
                aria-labelledby="contained-modal-title-vcenter"
                className=".modal-lg"
                style={{ zIndex: 1050, minWidth: "60vw" }}
                centered
            >
                <Modal.Header style={{ background: '#738678 ' }}>
                    <Modal.Title id="contained-modal-title-vcenter">{headerTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {body}
                </Modal.Body>
                <Modal.Footer className="py-0">
                    {footer}
                </Modal.Footer>
            </Modal>
        </>
    )
}
