import { useField } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import cn from "classnames";

export default function TextField({ size = "sm", placeholder = "", ...rest }) {
  const name = rest.name || "textField";
  const [field, meta, helpers] = useField(name);
  //   const { setValue, setTouched } = helpers;
  const { touched, error } = meta;
  return (
    <>
      <Form.Control
        {...field}
        placeholder={placeholder} //"Loan Range Minimum"
        size={size}
        type={rest.type}
        className={cn(rest.className, "mb-1")}
      />
      {touched && error ? (
        <span className="mt-4 text-danger">{error}</span>
      ) : null}
    </>
  );
}
