import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import SelectMultiple from "./FormElements/SelectMultiple";
import SingleSelectQueryForm from "./FormElements/SingleSelectQueryForm";
import SingleSelect from "./FormElements/SingleSelect";
import { FormikProvider, useFormik, Formik } from "formik";
import * as Yup from "yup";
import TextField from "./FormElements/TextField";
import { useNavigate } from "react-router";
import moment from "moment";
import DateRange from "./DateRange";
import { toast } from "react-toastify";
import { usecode } from "../common/options";
import { accountActionApi } from "../Redux/Action";
import { queryActionApi } from "../Redux/Action/QueryAction";
import LoadQueryModal from "./LoadQueryModal";
import SaveQueryModal from "./SaveQueryModal";
import AsyncSelectPaginateOptionsQueryForm from "./FormElements/AsyncSelectPaginateOptionsQueryForm";
import CommonLoader from "../common/CommonLoader";

export default function QueryForm({
  setShowTable,
  setTableData,
  setIsQueryLoading,
}) {
  const [years, setYears] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [showLoadQuery, setShowLoadQuery] = React.useState(false);
  const [list, setList] = useState([]);
  const [states, setStates] = useState([]);
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [error, setError] = useState({
    dateError: "",
  });
  const [countyList, setCountyList] = useState([]);
  const [townList, setTownList] = useState([]);

  const [getProfile, { data: myUser, isLoading: isProfileLoading }] =
    accountActionApi.useGetProfileMutation();
  const [getState, { status: stateStatus, data: stateListData, isLoading: isStateLoading }] =
    queryActionApi.useGetStateMutation();
  const [getCounty, { status: countyStatus, data: countyListData, isLoading: isCountyLoading }] =
    queryActionApi.useGetCountyMutation();
  const [getTown, { status: townStatus, data: townListData, isLoading: isTownLoading }] =
    queryActionApi.useGetTownMutation();

  useEffect(() => {
    stateListData?.length > 0 && setStates(stateListData);
  }, [stateStatus, stateListData]);

  useEffect(() => {
    countyListData?.length > 0 && setCountyList(countyListData);
  }, [countyStatus, countyListData]);

  useEffect(() => {
    townListData?.length > 0 && setTownList(townListData);
  }, [townStatus, townListData]);

  const getInitialFormValues = () => ({
    isState: false,
    isCounty: false,
    isTown: false,
    isTimePeriodInYear: true,
    reportformat: "HTML", // not found
    reporttype: "Ranking Report", // not found
    reportrank: { value: "$ Volume", label: "$ Volume" },
    pmmnonpmm: { value: "Any", label: "Any" }, // Sort by:
    usecode: [],
    addgroup: { value: "Any", label: "Any" }, //Government/Conventional
    lendertype: { value: "Any", label: "Any" },
    lenderstodisplay: { value: "5", label: "5" }, // Loan Originators To Display
    state: [],
    county: [],
    year: [],
    period: [],
    refionly: true,
    loantypessubbypass: false,
    loanmin: "",
    loanmax: "",
    nmlsid: "",
    summarizeby: { value: "Nationwide", label: "Nationwide" },
    allowcustomregion: true, // not found in query form pass default true to server
    customregion: false,
    citytown: [], // not found in query form pass default true to server
    zipcode: [], // not found in query form pass default true to server
    censustract: [], // not found in query form pass default true to server

    reporting: {}, // not found in query form pass default true to server
    lt: { lendertype: "", lendertypesetup: false }, // not found in query form pass default true to server
  });
  const [form, setForm] = useState(getInitialFormValues());
  const [loadQuery] = queryActionApi.useLoadQueryMutation();
  const getList = () =>
    loadQuery({
      usr_id: 1,
    }).then((res) => setList(res.data?.data?.data));

  const setDateWithValue = (key, value) => {
    formik.setFieldValue("year", []);
    formik.setFieldValue("period", []);
    let state = {
      ...date,
      [key]: value,
    };
    if (!state.startDate && !state.endDate) {
      formik.setFieldValue("isTimePeriodInYear", true);
    } else {
      formik.setFieldValue("isTimePeriodInYear", false);
    }
    setDate(state);
  };
  const navigate = useNavigate();

  const getStateByCounty = (county, state) => {
    const parentStateCheck = state.map(x => {
      if(x.county===county){
        return x.state
      }
    });
    const data_one = countyListData.map(x => x.options.filter(y => y.value === county && y.state === parentStateCheck[0]))
    const data_two = data_one.filter(x => x?.length > 0).map(y => y.map(p => p.state))[0]
    return data_two[0];
  }

  const validationSchema = Yup.object({
    isTimePeriodInYear: Yup.boolean(),
    isState: Yup.boolean(),
    isCounty: Yup.boolean(),
    isTown: Yup.boolean(),
    year: Yup.array().when("isTimePeriodInYear", {
      is: true,
      then: Yup.array().min(1, "Year is Required").required("Year is Required"),
    }), //.min(1, "Year is Required").required("Year is Required"),
    period: Yup.array().when("isTimePeriodInYear", {
      is: true,
      then: Yup.array()
        .min(1, "Period is Required")
        .required("Period is Required"),
    }),
    state: Yup.array().when("isState", {
      is: true,
      then: Yup.array()
        .min(1, "state is Required")
        .required("Please enter state"),
    }),
    county: Yup.array().when("isCounty", {
      is: true,
      then: Yup.array()
        .min(1, "County is Required")
        .required("County is Required"),
    }),
    citytown: Yup.array().when("isTown", {
      is: true,
      then: Yup.array().min(1, "Town is Required").required("Town is Required"),
    }),
  });
  const [queryFetchAction, resp] = queryActionApi.useFetchQueryMutation();
  const [saveQueryAction, saveQueryResponse] =
    queryActionApi.useSaveQueryMutation();

  const handleSave = ({ name }) => {
    saveQueryAction({
      q_name: name,
      usr_id: 1,
      model: getPayload(formik.values),
    });
    getList();
    setModalShow(false);
    toast.success("Query Saved");
  };

  useEffect(() => {
    if (resp.data) {
      setTableData(resp.data);
      setShowTable(true);
    }
  }, [resp?.data]);

  const checkFormat = (checked_date) =>
    checked_date && moment(checked_date, "MM/DD/YYYY", true).isValid();
  const getDateDiff = (startDate, endDate) => {
    const sd = moment(startDate);
    const ed = moment(endDate);
    return (unitOfTime = "years") => ed.diff(sd, unitOfTime);
  };

  const getDate = (date) => moment(date, "MM/DD/YYYY").format("MM-DD-YYYY");
  const checkErrorInDate = (isTimePeriodInYear) => {
    if (!isTimePeriodInYear) {
      if (checkFormat(date.endDate) && checkFormat(date.startDate)) {
        const dateRangeDiff = getDateDiff(date.startDate, date.endDate);
        if (dateRangeDiff("days") < 0) {
          setError({ dateError: "Invalid date range" });
          return true;
        } else if (dateRangeDiff() > 10) {
          setError({
            dateError: "Please contact Admin for more than 10 years of data.",
          });
          return true;
        }
        setError({
          dateError: "",
        });
        return false;
      }
      setError({
        dateError: "Date range is required",
      });
      return true;
    }
    setError({
      dateError: "",
    });
    return false;
  };

  const getPayload = (values) => ({
    reporttype: "Ranking Report", // not found
    reportrank: values.reportrank?.value,
    reportformat: "HTML", // not found
    usecode: values.usecode?.map((item) => item.value), // need to update
    lendertype: values.lendertype.value, //"Finance/Mortgage Cos",
    lenderstodisplay: values.lenderstodisplay.value, //"10",
    addgroup: values.addgroup.value,
    pmmnonpmm: values.pmmnonpmm.value,
    refionly: values.refionly,
    loantypessubbypass: values.loantypessubbypass,
    loanmin: values.loanmin,
    loanmax: values.loanmax,
    nmlsid: values.nmlsid,
    summarizeby: values.summarizeby.value,
    allowcustomregion: true,
    customregion: values.customregion,
    state: values.state.map((item) => ({
      state: item.value,
      _uiSelectChoiceDisabled: false,
    })),
    county: values.county.map((item) => ({
      state: item.state,
      county: item.label,
      _uiSelectChoiceDisabled: false,
    })),
    citytown: values.citytown.map((item) => ({
      state: getStateByCounty(item.county, values.county.map((item) => ({
        state: item.state,
        county: item.label,
        _uiSelectChoiceDisabled: false,
      }))),
      county: item.county,
      town: item.label,
      _uiSelectChoiceDisabled: false,
    })),
    zipcode: [],
    censustract: [],
    year: values.year ? values.year.map((item) => item.value) : [],
    period: values.period ? values.period.map((item) => item.value) : [], //["Annual"],
    reporting: {},

    lt: { lendertype: "", lendertypesetup: false },
    isdaterange: !values.isTimePeriodInYear,
    daterange: values.isTimePeriodInYear
      ? {
        startdate: "",
        enddate: "",
      }
      : {
        startdate: getDate(date.startDate),
        enddate: getDate(date.endDate),
      },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: form,
    validationSchema,
    onSubmit: async (values) => {
      if (!checkErrorInDate(values.isTimePeriodInYear)) {
        setIsQueryLoading(true);
        const payload = getPayload(values);
        await queryFetchAction(payload).then(() => {
          // resetForm();
          setIsQueryLoading(false);
        });
      }
    },
  });

  const { values } = formik;
  const selectedStateList = values.state.map((item) => item.value);
  const selectedCountyList = values.county.map((item) => item.label);

  useEffect(() => {
    if (values.summarizeby.value !== "State Level") {
      if (selectedStateList.includes("All")) {
        setCountyList(countyListData);
      } else {
        const countyList = countyListData?.filter((item) => {
          return selectedStateList.includes(item.label);
        });
        setCountyList(countyList);
      }
    } else {
      setCountyList([]);
    }
  }, [values.state.length, values.summarizeby]);


  useEffect(() => {
    if (values.summarizeby.value !== "County Level" && values.summarizeby.value !== "State Level") {
      if (selectedCountyList.includes("All")) {
        setTownList(townListData);
      } else {
        const townList = townListData?.filter((item) => {
          return selectedCountyList.includes(item.label);
        });
        setTownList(townList);
      }
    } else {
      setTownList([]);
    }
  }, [values.county.length, values.summarizeby]);

  useEffect(() => {
    if (selectedStateList.includes("All") && values.state?.length > 1) {
      const state = values.state.filter((item) => item.value == "All");
      formik.setFieldValue("state", state);
    }
    if (selectedCountyList.includes("All") && values.county?.length > 1) {
      const county = values.county.filter((item) => item.label == "All");
      formik.setFieldValue("county", county);
    }
  }, [values.state, values.county, formik, selectedStateList, selectedCountyList]);
  useEffect(() => {
    getProfile();
    let currentYear = new Date().getFullYear();
    let allYears = [];
    for (let i = 0; i < 10; i++) {
      allYears.push({
        label: currentYear,
        value: currentYear,
      });
      currentYear -= 1;
    }
    setYears(allYears);
  }, []);
  const resetForm = () => {
    setForm(getInitialFormValues());
    formik.resetForm();
    setDate({
      endDate: "",
      startDate: "",
    });
    setError({
      dateError: "",
    });
  };
  const loadSelected = () => {
    let data = selectedRadio?.q_parms || "";

    data = data.replaceAll("False", false);
    data = data.replaceAll("True", true);
    data = eval("(" + data + ")");
    const prev = getInitialFormValues();
    setForm(() => ({
      ...prev,
      reportrank: { value: data.reportrank, label: data.reportrank },
      // loanpurpose: { value: data.loanpurpose, label: data.loanpurpose },
      pmmnonpmm: { value: data.pmmnonpmm, label: data.pmmnonpmm },

      usecode: data.usecode
        ? data.usecode.map((item) => ({
          value: item,
          label: item.usecode,
        }))
        : [],
      addgroup: { value: data.addgroup, label: data.addgroup }, //Government/Conventional
      // brokerlenderbypass: data.brokerlenderbypass,
      lendertype: { value: data.lendertype, label: data.lendertype },
      lenderstodisplay: {
        value: data.lenderstodisplay,
        label: data.lenderstodisplay,
      },
      // lenders: data.lenders,
      // loantypes: { value: data.loantypes, label: data.loantypes },
      // loantypessub: data.loantypessub
      //   ? data.loantypessub.map((item) => ({
      //       value: item,
      //       label: item,
      //     }))
      //   : [],
      refionly: data.refionly,
      // excl_usahud: data.excl_usahud,
      nmlsid: data.nmlsid,
      loantypessubbypass: data.loantypessubbypass,
      loanmin: data.loanmin,
      loanmax: data.loanmax,
      summarizeby: { value: data.summarizeby, label: data.summarizeby },
      customregion: data.customregion,
      state: data.state
        ? data.state.map((item) => ({
          value: item.state,
          label: item.state,
        }))
        : [],
      county: data.county
        ? data.county.map((item) => ({
          state: item.state,
          label: item.county,
        }))
        : [],
      citytown: data.citytown
        ? data.citytown.map((item) => ({
          value: item,
          label: item.town,
        }))
        : [],
      year: data.year
        ? data.year.map((item) => ({
          value: item,
          label: item,
        }))
        : [],
      period: data.period
        ? data.period.map((item) => ({
          value: item,
          label: item,
        }))
        : [],
      isdaterange: data.isdaterange,
      isTimePeriodInYear: !data.isdaterange,
      // allowcustomregion: true,

      // citytown: [],
      // zipcode: [],
      // censustract: [],
      // reporting: {},
      // lt: {
      //   lendertype: "",
      //   lendertypesetup: false,
      // },
    }));
    if (data.isdaterange) {
      setDate({
        endDate: moment(data.daterange.enddate, "MM-DD-YYYY").toDate(),
        startDate: moment(data.daterange.startdate, "MM-DD-YYYY").toDate(),
      });
    } else {
      setDate({
        endDate: "",
        startDate: "",
      });
    }
    toast.success("Query has been loaded.\nRun to see Results!");
    setShowLoadQuery(false);
  };

  const {
    usr_county_permission = [],
    usr_state_permission = [],
    usr_citytown_permission = [],
    usr_quarter_permission = [],
    usr_year_permission = [],
  } = myUser || {};
  const filterByLabel = (arr = [], perm = []) => {
    return arr.filter((item) => perm.includes(item.label))
  };

  const filterByGroupOptions = (arr = [], labelCheck = [], valueCheck = []) => {
    let results = arr.filter((item) => labelCheck.includes('All') ? !labelCheck.includes(item.label) : labelCheck.includes(item.label));
    results = results.map((item) => ({
      ...item,
      options: item.options.filter((opt) => valueCheck.includes(opt.label)),
    }));
    return results;
  };

  useEffect(() => {
    // usr_state_permission?.length > 0 && getState();
    usr_state_permission?.length > 0 && getCounty(usr_state_permission);
  }, [usr_state_permission, getCounty]);

  useEffect(() => {
    usr_county_permission?.length > 0 && getTown(usr_county_permission);
  }, [usr_county_permission, getTown]);

  useEffect(()=>{
    if((stateListData?.length === 0 || !stateListData) && usr_state_permission?.length > 0){
      getState();
    }
  }, [stateListData, usr_state_permission, getState])

  return (
    <>
      {isProfileLoading || isStateLoading || isCountyLoading || isTownLoading ? <CommonLoader style={{ height: "100vh", position: "absolute", width: '100vw', background: '#eeeeee' }} /> :
    <>
      <LoadQueryModal
        list={list}
        getList={getList}
        onSave={handleSave}
        show={showLoadQuery}
        onHide={() => setShowLoadQuery(false)}
        setSelectedRadio={setSelectedRadio}
        loadSelected={loadSelected}
      />
      <SaveQueryModal
        onSave={handleSave}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <FormikProvider value={formik}>
        <form className="forms-sample">
          <div className="sidbar-options">
            <h5 className="card-title bg-secondary p-2">Report Options</h5>
            <div className="px-2">
              <Form.Group>
                <label htmlFor="exampleInputName1">
                  Loan Originator Rank By:
                </label>
                <SingleSelect
                  name="reportrank"
                  placeholder="Rank by"
                  options={[
                    { value: "$ Volume", label: "$ Volume" },
                    { value: "# Loans", label: "# Loans" },
                  ]}
                />
              </Form.Group>
              <Form.Group>
                <label htmlFor="exampleInputName1">Sort by:</label>
                <SingleSelect
                  name="pmmnonpmm"
                  placeholder="Sort by"
                  options={[
                    { value: "Any", label: "Any" },
                    {
                      value: "Purchase Mortgages",
                      label: "Purchase Mortgages",
                    },
                    {
                      value: "Non Purchase Mortgages",
                      label: "Non Purchase Mortgages",
                    },
                  ]}
                />
              </Form.Group>
              <Form.Group>
                <label htmlFor="exampleInputName1">Property Type:</label>
                <SelectMultiple
                  name="usecode"
                  placeholder="Select Property Type"
                  groupedOptions={usecode}
                  isMulti
                />
              </Form.Group>
              <Form.Group>
                <label htmlFor="exampleInputName1">
                  Government/Conventional :
                </label>
                <SingleSelect
                  name="addgroup"
                  options={[
                    { value: "Any", label: "Any" },
                    { value: "Government", label: "Government" },
                    { value: "Conventional", label: "Conventional" },
                  ]}
                />
              </Form.Group>
            </div>
            <h5 className="card-title bg-secondary p-2">
              Loan Originator Options
            </h5>
            <div className="forms-sample px-2">
              <Form.Group>
                <label htmlFor="exampleInputName1">Lender Type:</label>
                <SingleSelect
                  name="lendertype"
                  placeholder="Select Lender Type"
                  defaultValue={{ value: "Any", label: "Any" }}
                  options={[
                    { value: "Any", label: "Any" },
                    {
                      value: "Banks",
                      label: "Banks",
                    },
                    {
                      value: "Credit Unions",
                      label: "Credit Unions",
                    },
                    {
                      value: "Finance/Mortgage Cos",
                      label: "Finance/Mortgage Cos",
                    },
                    {
                      value: "Other",
                      label: "Other",
                    },
                  ]}
                />
              </Form.Group>
              <Form.Group>
                <label htmlFor="exampleInputName1">
                  Loan Originators To Display:
                </label>

                <SingleSelect
                  name="lenderstodisplay"
                  placeholder="Loan Originators To Display"
                  options={[
                    { value: "5", label: "5" },
                    { value: "10", label: "10" },
                    { value: "15", label: "15" },
                    { value: "20", label: "20" },
                    { value: "25", label: "25" },
                    { value: "50", label: "50" },
                    { value: "100", label: "100" },
                    { value: "250", label: "250" },
                    { value: "500", label: "500" },
                    { value: "1000", label: "1000" },
                    { value: "2000", label: "2000" },
                    { value: "3000", label: "3000" },
                    { value: "4000", label: "4000" },
                    { value: "4500", label: "4500" },
                    { value: "5000", label: "5000" },
                  ]}
                />
              </Form.Group>
              <Form.Group>
                <label htmlFor="exampleInputName1">NMLS Number Lookup:</label>
                <TextField
                  name="nmlsid"
                  type="text"
                  placeholder="Select NMLS Number Lookup"
                  size="sm"
                />
              </Form.Group>
            </div>
            <h5 className="card-title bg-secondary p-2">Loan Parameters</h5>
            <div className="forms-sample px-2">
              <Form.Group>
                <label htmlFor="exampleInputName1">Loan Range Minimum:</label>
                <TextField
                  name="loanmin"
                  type="text"
                  placeholder="Select Loan Range Minimum"
                  size="sm"
                />
              </Form.Group>
              <Form.Group>
                <label htmlFor="exampleInputName1">Loan Range Maximum:</label>
                <TextField
                  name="loanmax"
                  type="text"
                  placeholder="Select Loan Range Maximum"
                  size="sm"
                />
              </Form.Group>
              <Form.Group>
                <label className="align-self-center">
                  Show Only Refi in Non-PMM:
                </label>
                <input
                  id="refionly"
                  name="refionly"
                  type="checkbox"
                  className="form-check-input ml-2 align-self-center mt-0"
                  onChange={(e) =>
                    formik.setFieldValue("refionly", e.target.checked)
                  }
                  checked={values.refionly}
                />
              </Form.Group>
              <Form.Group>
                <label className="align-self-center">
                  Show Only HELOC/Home Equity Loan in Non-PMM:
                </label>
                <input
                  id="loantypessubbypass"
                  name="loantypessubbypass"
                  type="checkbox"
                  className="form-check-input ml-2 align-self-center mt-0"
                  onChange={(e) =>
                    formik.setFieldValue("loantypessubbypass", e.target.checked)
                  }
                  checked={values.loantypessubbypass}
                />
              </Form.Group>
            </div>

            <h5 className="card-title bg-secondary p-2">Geography</h5>
            <div className="forms-sample px-2">
              <Form.Group>
                <label htmlFor="exampleInputName1">Summarize Results By:</label>

                <SingleSelectQueryForm
                  name="summarizeby"
                  placeholder="Select Summarize Results"
                  options={[
                    { value: "Nationwide", label: "Nationwide" },
                    {
                      value: "State Level",
                      label: "State Level",
                    },
                    {
                      value: "County Level",
                      label: "County Level",
                    },
                    {
                      value: "Town Level",
                      label: "Town Level",
                    },
                  ]}
                  onChange={(value) => {
                    if (value.label === "Town Level") {
                      formik.setFieldValue("isCounty", true);
                      formik.setFieldValue("isTown", true);
                    } else if (value.label == "Nationwide") {
                      formik.setFieldValue("isCounty", false);
                      formik.setFieldValue("isState", false);
                      formik.setFieldValue("isTown", false);
                    } else if (value.label == "County Level") {
                      formik.setFieldValue("isState", true);
                      formik.setFieldValue("isCounty", true);
                      formik.setFieldValue("isTown", false);
                    } else if (value.label == "State Level") {
                      formik.setFieldValue("isState", true);
                      formik.setFieldValue("isCounty", false);
                      formik.setFieldValue("isTown", false);
                    }
                    formik.setFieldValue("summarizeby", value);
                  }}
                />
              </Form.Group>
              <Form.Group>
                <label htmlFor="exampleInputName1">Run As Custom Region:</label>
                <input
                  name="customregion"
                  type="checkbox"
                  className="form-check-input ml-2 align-self-center mt-0"
                  checked={values.customregion}
                  onChange={(e) =>
                    formik.setFieldValue("customregion", e.target.checked)
                  }
                />
              </Form.Group>
              <Form.Group>
                <label className="align-self-center">State:</label>
                <SingleSelectQueryForm
                  name="state"
                  placeholder="Select state"
                  options={usr_state_permission.includes('All') ? states : filterByLabel(states, usr_state_permission)}
                  isMulti
                  isDisableField={values.summarizeby.value === "Nationwide"}
                  isDisabled={values.summarizeby.value === "Nationwide"}
                  closeOnSelect={false}
                  closeMenuOnSelect={false}
                  onSelectResetsInput={false}
                />
              </Form.Group>
              <Form.Group>
                <label className="align-self-center">County:</label>
                <SingleSelectQueryForm
                  name="county"
                  placeholder="Select county"
                  options={usr_county_permission.includes('All') ? countyList : filterByGroupOptions(
                    countyList,
                    usr_state_permission,
                    usr_county_permission
                  )}
                  isDisableField={
                    !countyList?.length ||
                    values.summarizeby.value === "State Level"
                  }
                  isDisabled={
                    !countyList?.length ||
                    values.summarizeby.value === "State Level"
                  }
                  isMulti
                  closeOnSelect={false}
                  closeMenuOnSelect={false}
                  onSelectResetsInput={false}
                />

                {/*  */}
              </Form.Group>
              <Form.Group>
                <label className="align-self-center">Town:</label>
                {/* <SingleSelectQueryForm
                  name="citytown"
                  placeholder="Select Town"
                  options={usr_citytown_permission.includes('All') ? townList : filterByGroupOptions(
                    townList,
                    usr_county_permission,
                    usr_citytown_permission
                  )}
                  isDisabled={
                    values.summarizeby.value !== "Town Level" ||
                    !values.county.length
                  }
                  isMulti
                /> */}
                <AsyncSelectPaginateOptionsQueryForm
                  name="citytown"
                  placeholder="Select Town"
                  selectedCountyList={selectedCountyList}
                  usr_county_permission = {usr_county_permission}
                  usr_citytown_permission = {usr_citytown_permission}
                  townList={townList}
                  isMulti
                  isDisableField={
                    values.summarizeby.value !== "Town Level" ||
                    !values.county.length
                  }
                  isDisabled={
                    values.summarizeby.value !== "Town Level" ||
                    !values.county.length
                  }
                />
                {/*  */}
              </Form.Group>
            </div>

            <h5 className="card-title bg-secondary p-2">Time Periods</h5>
            <div className="forms-sample px-2 mb-2">
              <Form.Group>
                <label className="align-self-center">Year(s):</label>
                <SingleSelectQueryForm
                  name="year"
                  placeholder="Select Year"
                  options={filterByLabel(years, usr_year_permission)}
                  isMulti
                  setValueCallback={() => {
                    setDate({
                      endDate: "",
                      startDate: "",
                    });
                    formik.setFieldValue("isTimePeriodInYear", true);
                    formik.setFieldError("year", undefined);
                  }}
                />
              </Form.Group>
              <Form.Group>
                <label className="align-self-center">Period(s):</label>
                <SingleSelectQueryForm
                  name="period"
                  placeholder="Select Period"
                  options={filterByLabel(
                    [
                      { value: "Annual", label: "Annual" },
                      { value: "YTD", label: "YTD" },
                      { value: "Q1", label: "Q1" },
                      { value: "Q2", label: "Q2" },
                      { value: "Q3", label: "Q3" },
                      { value: "Q1+Q2", label: "Q1+Q2" },
                      { value: "Q2+Q3", label: "Q2+Q3" },
                      { value: "Q3+Q4", label: "Q3+Q4" },
                      { value: "Q1+Q2+Q3", label: "Q1+Q2+Q3" },
                      { value: "January", label: "January" },
                      { value: "February", label: "February" },
                      { value: "March", label: "March" },
                      { value: "April", label: "April" },
                      { value: "May", label: "May" },
                      { value: "June", label: "June" },
                      { value: "July", label: "July" },
                      { value: "August", label: "August" },
                      { value: "September", label: "September" },
                      { value: "October", label: "October" },
                      { value: "November", label: "November" },
                      { value: "December", label: "December" },
                      { value: "Past 3 Months", label: "Past 3 Months" },
                      { value: "Past 6 Months", label: "Past 6 Months" },
                      { value: "Past 9 Months", label: "Past 9 Months" },
                      { value: "Past 12 Months", label: "Past 12 Months" },
                    ],
                    usr_quarter_permission
                  )}
                  isMulti
                  setValueCallback={() => {
                    setDate({
                      endDate: "",
                      startDate: "",
                    });
                    formik.setFieldValue("isTimePeriodInYear", true);
                    formik.setFieldError("year", undefined);
                  }}
                />
              </Form.Group>
              <Form.Group className="text-center font-weight-bold mb-0">
                <h4> or </h4>
              </Form.Group>
              <Form.Group>
                <label className="align-self-center">Date Range</label>
                <DateRange date={date} setDate={setDateWithValue} />
                {error.dateError && (
                  <span className="text-danger">{error.dateError}</span>
                )}
              </Form.Group>
            </div>
          </div>
          <div className="twg-slide-lower-left-corner bg-secondary py-3">
            <div className="twg-full-btn-space row m-0 text-center">
              <div className="w-50 p-2">
                <button
                  type="button"
                  onClick={() => {
                    checkErrorInDate(values.isTimePeriodInYear);
                    formik.handleSubmit();
                  }}
                  className="btn btn-primary px-3 btn-sm"
                >
                  Run Query
                </button>
              </div>
              <div className="w-50 p-2">
                <button
                  onClick={resetForm}
                  type="button"
                  className="btn btn-primary px-3 btn-sm"
                >
                  Clear Query
                </button>
              </div>
              <div className="w-50 p-2">
                <button
                  onClick={() => setModalShow(true)}
                  type="button"
                  className="btn btn-primary px-3 btn-sm"
                >
                  Save Query
                </button>
              </div>
              <div className="w-50 p-2">
                <button
                  onClick={() => setShowLoadQuery(true)}
                  type="button"
                  className="btn btn-primary px-3 btn-sm"
                >
                  Load Query
                </button>
              </div>
              <div className="w-100 p-2 pb-0">
                <button
                  type="button"
                  className="btn btn-primary btn-sm btn-block px-3"
                  onClick={() => {
                    localStorage.clear();
                    navigate("/login");
                  }}
                >
                  Log Out
                </button>
              </div>
            </div>
          </div>
        </form>
      </FormikProvider>
    </>}
    </>
  );
}