const sleep = (ms) =>
    new Promise((resolve) => {
        setTimeout(() => {
        resolve(undefined);
        }, ms);
    });
  
  const optionsPerPage = 10;
  
  export const loadSelectOptions = async (search, page, data) => {
    await sleep(1000);
  
    let filteredOptions;
    if (!search) {
      filteredOptions = data;
    } else {
      const searchLower = search.toLowerCase();
  
      filteredOptions = data.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }
  
    const hasMore = Math.ceil(filteredOptions.length / optionsPerPage) > page;
    const slicedOptions = filteredOptions.slice(
      (page - 1) * optionsPerPage,
      page * optionsPerPage
    );
  
    // const mapTypeToIndex = new Map();
  
    // const result = [];
  
    // slicedOptions.forEach((option) => {
    //   const { type } = option;
  
    //   if (mapTypeToIndex.has(type)) {
    //     const index = mapTypeToIndex.get(type);
  
    //     result[index].options.push(option);
    //   } else {
    //     const index = result.length;
  
    //     mapTypeToIndex.set(type, index);
  
    //     result.push({
    //       label: `Type #${type}`,
    //       options: [option]
    //     });
    //   }
    // });
  
    return {
      options: slicedOptions,
      hasMore
    };
  };
  