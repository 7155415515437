import { useField } from "formik";
import React, { useState, useEffect } from "react";
import {
  AsyncPaginate,
  reduceGroupedOptions,
} from "react-select-async-paginate";
import { loadSelectOptions } from "../../common/loadSelectOptions";

const filterByLabel = (arr = [], perm = []) => {
    return arr.filter((item) => perm.includes(item.label))
  };

export default function AsyncSelectPaginateOptions({ selectedCountyList, townList, ...rest }) {
  const name = rest.name || "multipleSelect";
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const { touched, error } = meta;
  const [selectedValue, setSelectedValue] = useState(null);
  const [optionList, setOptionList] = useState([]);
  useEffect(()=>{
    setOptionList(townList)
  },[townList]);

  useEffect(()=>{
    if(Array.isArray(field?.value) && field?.value?.length > 0){
      setSelectedValue(field?.value)
    }
  },[field]);

  useEffect(()=>{
    var valList = selectedValue?.map(x=>x.value)
    if(Array.isArray(selectedValue) && selectedValue.length > 0 && valList.includes('All') && field.name !== "usr_state_permission"){
      let data_one = townList?.filter(x => x?.label !== 'All')
      let data_two = data_one?.map(y => {
        let p = y?.options?.map(p => ({...p, isDisabled: true}));
        return {
          label: y?.label,
          options: p
        }
      })
      setOptionList(data_two)
    }
    
    else if(Array.isArray(selectedValue) && selectedValue.length > 0 && !valList.includes('All') && field.name !== "usr_state_permission") {
      let data_one = townList;
      let data_two = data_one?.map(y => {
        let p = y?.options?.map(p => ({...p, isDisabled: false}));
        return {
          label: y?.label,
          options: p
        }
      })
      setOptionList(data_two)
    }

    else if(Array.isArray(selectedValue) && selectedValue.length === 0 && field.name !== "usr_state_permission") {
      let data_one = townList;
      let data_two = data_one?.map(y => {
        let p = y?.options?.map(p => ({...p, isDisabled: false}));
        return {
          label: y?.label,
          options: p
        }
      })
      setOptionList(data_two)
    }
  },[selectedValue]);

  const loadOptionsWrapper = async (q, prevOptions, { page }) => {
  const { options, hasMore } = await loadSelectOptions(q, page, selectedCountyList.includes('All') ? optionList : filterByLabel(optionList, selectedCountyList));
  return {
    options,
    hasMore,
    additional: {
      page: page + 1
    }
  };
};

const defaultAdditional = {
    page: 1
  };

  return (
    <>
      <AsyncPaginate
        {...field}
        additional={defaultAdditional}
        onChange={(val)=>{setValue(val); setSelectedValue(val)}}
        loadOptions={loadOptionsWrapper}
        reduceOptions={reduceGroupedOptions}
        cacheUniqs={[selectedCountyList]}
        isMulti
        {...rest}
      />
      {touched && error ? <span className="text-danger">{error}</span> : null}
    </>
  );
}
