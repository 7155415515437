import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import CommonModal from "../common/CommonModal";
import { accountActionApi } from "../Redux/Action/AccountSettingsAction";
import CommonLoader from "../common/CommonLoader";

export default function BasicTable({ tableData = {}, nmlsId }) {
  const { report_ary, reportheader_ary, subheader, subtitle } = tableData;
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedNmlsId, setSelectedNmlsId] = useState(Number);
  const [isLoader, setIsLoader] = useState(false);

  const { data: detail, isLoading } = accountActionApi.useGetQueryDetailQuery(selectedNmlsId);

  if (isLoading) {
    <CommonLoader />
  }
  const handleDetailClick = (tableRow) => {
    setIsLoader(true);
    setSelectedNmlsId(tableRow)
    setModalOpen(true)
  }
  useEffect(() => {
    if (detail?.status === 200) {
      setIsLoader(false)
    }
  }, [detail])
  return (
    <>
      <div className="row">
        {report_ary?.map((tableBody, index) => {
          const [property, filterHeader, quarter, type, dateAndTime] =
            reportheader_ary[index];
          const [total, purchaseMortgages, nonPurchaseMortgages] = subheader;
          const [rank, loanOriginatorName, NMLS, authorizedToRepresent, amount, amountValue, amountTwo, amountTwoValue, amountThree, amountThreeValue] = subtitle;
          return (
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body p-2">
                  <div className="d-flex justify-content-between mx-2">
                    <span className="w-65">
                      {property} - Loan Originator Report
                    </span>
                    <span className="">{quarter}</span>
                  </div>
                  <div className="d-flex justify-content-between m-2">
                    <span className="w-65">{filterHeader}</span>
                    <span className="">Report Time: {dateAndTime}</span>
                  </div>
                  <div className="d-flex justify-content-center pb-2 mb-2 border-dark border-bottom">
                    <span>Rank by {type}</span>
                  </div>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr class="subheader">
                          <th colspan="4">&nbsp;</th>
                          <th colspan="2" class="rptshd2">
                            {total}
                          </th>
                          <th colspan="2" class="rptshd3">
                            {purchaseMortgages}
                          </th>
                          <th colspan="2" class="rptshd4">
                            {nonPurchaseMortgages}
                          </th>
                        </tr>
                        <tr class="subheader">
                          <th>{rank}</th>
                          <th>{loanOriginatorName}</th>
                          <th>{NMLS}</th>
                          <th>{authorizedToRepresent}</th>
                          <th>
                            {amount}
                          </th>
                          <th>
                            {amountValue}
                          </th>
                          <th>
                            {amountTwo}
                          </th>
                          <th>
                            {amountTwoValue}

                          </th>
                          <th>
                            {amountThree}
                          </th>
                          <th>
                            {amountThreeValue}
                          </th>
                          {/* <th>All</th>
                        <th>P</th>
                        <th>NP</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {tableBody.length ? (
                          tableBody.map((tableRow, index) => {
                            return (
                              <tr>
                                {tableRow.map((td, index) => (
                                 index === (0 || 1) && tableRow[10] ? <td onClick={() => handleDetailClick(tableRow[2])} style={{color:"blue", cursor:"pointer", textDecoration:"underline"}}>{td}</td> : <td>{td}</td>
                                ))}
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="text-center font-weight-bold">
                            <td colspan="13">No Data Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <CommonModal
        show={modalOpen}
        headerTitle='Loan Originator Contact Information'
        body={selectedNmlsId && isLoader ?
          (<CommonLoader />) :
          detail?.data === "No data available" ?
            (<div style={{ display: 'flex', justifyContent: 'center', fontSize: '22px', fontWeight: 650 }}>{detail?.data}</div>)
            :
            (<Row className="d-flex align-items-left justify-content-left text-left">
              <Col>
                {/* <Col>
                  <b>{detail?.data?.cFL_LO || "No Data Available"}</b>
                </Col> */}
                <Col>
                  <span>NMLS ID#</span> <span><b>: {detail?.data?.LOOrgID || "No Data Available"}</b></span>
                </Col>
                <Col>
                  Company Name <b>: {detail?.data?.Collected_EmployerCOMPANY || "No Data Available"}</b>
                </Col>
                <Col>
                  Company ID <b>: {detail?.data?.Collected_EmployerCOMPANYID || "No Data Available"}</b>
                </Col>
              </Col>
              <Col>
                <Col>
                  Office Phone <b>: {detail?.data?.Collected_OfficePhone || "No Data Available"}</b>
                </Col>
                <Col>
                  Cell Phone <b>: {detail?.data?.Collected_CellPhone || "No Data Available"}</b>
                </Col>
                <Col>
                  Personal Email <b>: {detail?.data?.Collected_PersonalEmail || "No Data Available"}</b>
                </Col>
                <Col>
                  Work Email <b>: {detail?.data?.Collected_WorkEmail || "No Data Available"}</b>
                </Col>
                <Col>
                  Company Website <b>: {detail?.data?.Collected_EmployerWebsite || "No Data Available"}</b>
                </Col>
                <Col>
                  Personal Website <b>: {detail?.data?.Collected_LOWebSite || "No Data Available"}</b>
                </Col>
                <Col>
                  LinkedIn <b>: {detail?.data?.Collected_Linkedin || "No Data Available"}</b>
                </Col>
                <Col>
                  Facebook <b>: {detail?.data?.Collected_Facebook || "No Data Available"}</b>
                </Col>
                <Col>
                  Twitter <b>: {detail?.data?.Collected_Twitter || "No Data Available"}</b>
                </Col>
              </Col>
            </Row>)

        }
        footer={
          <Button className="btn btn-primary w-20 mb-4" onClick={() => setModalOpen(false)}>
            Close
          </Button>
        }
      />
    </>
  );
}
