import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";

export default function SSOError() {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("access_token");
  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else if (
      location.pathname === "/" ||
      location.pathname === "/login" ||
      location.pathname === "/sso"
    ) {
      navigate("/home");
    }
  }, []);

  return <div>Redirecting...</div>;
}
