import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TextField from "./FormElements/TextField";
import { queryActionApi } from "../Redux/Action/QueryAction";
import UpdateQueryName from "./UpdateQueryName";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";

export default function LoadQueryModal(props) {
  const [deleteQuery] = queryActionApi.useDeleteQueryMutation();
  const { getList, list, setSelectedRadio } = props;
  useEffect(() => {
    if (props.show) {
      getList();
    }
  }, [props.show]);
  const onDelete = (data, callBack) => {
    deleteQuery(data).then((res) => {
      toast.success("Query has been deleted.");
      getList().then(callBack);
    });
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-md-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Load Query</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="d-flex align-items-center justify-content-center text-center">
          <Col md={1} sm={1} lg={1}>
            Select
          </Col>
          <Col md={4} sm={4} lg={4}>
            Name
          </Col>
          <Col md={3} sm={3} lg={3}></Col>
          <Col md={3} sm={3} lg={3}>
            Last Modified
          </Col>
        </Row>

        {list.map((item) => (
          <UpdateQueryName
            key={item.q_id}
            data={item}
            setSelectedRadio={setSelectedRadio}
            onDelete={(callBack) =>
              onDelete({ q_id: item.q_id, usr_id: item.usr_id }, callBack)
            }
          />
        ))}
      </Modal.Body>
      <Modal.Footer className="py-0">
        <Button
          className="btn btn-primary btn-sm "
          onClick={props.loadSelected}
        >
          Load selected
        </Button>
        <Button className="btn btn-light btn-sm " onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
