import React from 'react'

export default function CommonLoader({ style }) {
    return (
        <>
            <div class="d-flex justify-content-center align-items-center" style={style}>
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </>
    )
}
