import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { accountActionApi } from "./Redux/Action/AccountSettingsAction";

export default function SSO() {
  const [ssoAction, resp] = accountActionApi.useSsoMutation();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (resp.status == "fulfilled") {
      if (resp.data?.access_token && resp.data?.refresh_token) {
        localStorage.setItem("access_token", resp.data?.access_token);
        localStorage.setItem("refresh_token", resp.data?.refresh_token);
        navigate("/home");
      } else if (resp.data?.response_code == 400) {
        localStorage.clear();
        navigate("/login");
      } else {
        navigate("/");
      }
    }
  }, [resp.data]);
  useEffect(() => {
    if (params.token) {
      ssoAction({
        sso_token: params.token,
      });
    }
  }, [params]);

  return <div>Validating..</div>;
}
