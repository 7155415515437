export const usecode = [
  {
    label: "Any",
    options: [
      {
        value: {
          id: 0,
          usecode: "All",
          usecodegroup: "ANY",
          _uiSelectChoiceDisabled: false,
        },
        label: "All (Any)",
      },
    ],
  },
  {
    label: "Residential",
    options: [
      {
        value: {
          id: 1,
          usecodegroup: "RES",
          usecode: "All",
          _uiSelectChoiceDisabled: false,
        },
        label: "All (Residential)",
      },
      {
        value: {
          id: 3,
          usecodegroup: "RES",
          usecode: "1-Family",
          _uiSelectChoiceDisabled: false,
        },
        label: "1-Family",
      },
      {
        value: {
          id: 4,
          usecodegroup: "RES",
          usecode: "2-Family",
          _uiSelectChoiceDisabled: false,
        },
        label: "2-Family",
      },
      {
        value: {
          id: 5,
          usecodegroup: "RES",
          usecode: "3-Family",
          _uiSelectChoiceDisabled: false,
        },
        label: "3-Family",
      },
      {
        value: {
          id: 6,
          usecodegroup: "RES",
          usecode: "Condo",
          _uiSelectChoiceDisabled: false,
        },
        label: "Condo",
      },
      {
        value: {
          id: 7,
          usecodegroup: "RES",
          usecode: "Other",
          _uiSelectChoiceDisabled: false,
        },
        label: "Other",
      },
      {
        value: {
          id: 8,
          usecodegroup: "RES",
          usecode: "Res Land",
          _uiSelectChoiceDisabled: false,
        },
        label: "Res Land",
      },
    ],
  },
  // {
  //   label: "Commercial",
  //   options: [
  //     {
  //       value: {
  //         id: 2,
  //         usecodegroup: "COM",
  //         usecode: "All",
  //         _uiSelectChoiceDisabled: false,
  //       },
  //       label: "All",
  //     },
  //     {
  //       value: {
  //         id: 3,
  //         usecodegroup: "COM",
  //         usecode: "Apartments",
  //         _uiSelectChoiceDisabled: false,
  //       },

  //       label: "Apartments",
  //     },
  //     {
  //       value: {
  //         id: 4,
  //         usecodegroup: "COM",
  //         usecode: "Comm Land",
  //         _uiSelectChoiceDisabled: false,
  //       },

  //       label: "Comm Land",
  //     },
  //     {
  //       value: {
  //         id: 5,
  //         usecodegroup: "COM",
  //         usecode: "Hospitality",
  //         _uiSelectChoiceDisabled: false,
  //       },

  //       label: "Hospitality",
  //     },
  //     {
  //       value: {
  //         id: 6,
  //         usecodegroup: "COM",
  //         usecode: "Industrial",
  //         _uiSelectChoiceDisabled: false,
  //       },

  //       label: "Industrial",
  //     },
  //     {
  //       value: {
  //         id: 7,
  //         usecodegroup: "COM",
  //         usecode: "Medical",
  //         _uiSelectChoiceDisabled: false,
  //       },

  //       label: "Medical",
  //     },
  //     {
  //       value: {
  //         id: 8,
  //         usecodegroup: "COM",
  //         usecode: "Mixed Use",
  //         _uiSelectChoiceDisabled: false,
  //       },

  //       label: "Mixed Use",
  //     },
  //     {
  //       value: {
  //         id: 9,
  //         usecodegroup: "COM",
  //         usecode: "Office Bldg",
  //         _uiSelectChoiceDisabled: false,
  //       },

  //       label: "Office Bldg",
  //     },
  //     {
  //       value: {
  //         id: 10,
  //         usecodegroup: "COM",
  //         usecode: "Retail",
  //         _uiSelectChoiceDisabled: false,
  //       },

  //       label: "Retail",
  //     },
  //   ],
  // },
];
export const loadQueryList = {
  status_code: 200,
  message: "Success",
  data: {
    data: [
      {
        q_id: 6,
        usr_id: 1,
        q_name: "rahul",
        q_create_ts: "2023-01-09 04:54:09.273583",
        q_lastmod: "2023-01-09 04:54:09.273595",
        q_parms:
          "{'reporttype': 'Ranking Report', 'reportrank': '# Loans', 'reportformat': 'HTML', 'brokerlenderbypass': False, 'customregion': False, 'excl_usahud': False, 'loantypessubbypass': False, 'refionly': False, 'isdaterange': False, 'daterange': {}, 'usecode': {'id': 3, 'usecodegroup': 'RES', 'usecode': '1-Family', '_uiSelectChoiceDisabled': False}, 'loanpurpose': 'Purchase', 'lendertype': 'Banks', 'lenderstodisplay': '5', 'lenders': [], 'ltext': '', 'loantypes': 'Conforming', 'loantypessub': ['Home Equity Loan'], 'loanmin': '1', 'loanmax': '1111', 'summarizeby': 'State Level', 'allowcustomregion': True, 'state': [{'state': 'AK', '_uiSelectChoiceDisabled': False}, {'state': 'AZ', '_uiSelectChoiceDisabled': False}], 'county': [], 'citytown': [], 'zipcode': [], 'censustract': [], 'year': ['2021', '2022'], 'period': ['Q1', 'Q3'], 'reporting': {}, 'lt': {'lendertypesetup': True}}",
      },
      {
        q_id: 8,
        usr_id: 1,
        q_name: "rahul",
        q_create_ts: "2023-01-09 05:00:19.548657",
        q_lastmod: "2023-01-09 05:00:19.548674",
        q_parms:
          "{'reporttype': 'Ranking Report', 'reportrank': '# Loans', 'reportformat': 'HTML', 'brokerlenderbypass': False, 'customregion': False, 'excl_usahud': False, 'loantypessubbypass': False, 'refionly': False, 'isdaterange': False, 'daterange': {}, 'usecode': {'id': 3, 'usecodegroup': 'RES', 'usecode': '1-Family', '_uiSelectChoiceDisabled': False}, 'loanpurpose': 'Purchase', 'lendertype': 'Banks', 'lenderstodisplay': '5', 'lenders': [], 'ltext': '', 'loantypes': 'Conforming', 'loantypessub': ['Home Equity Loan'], 'loanmin': '1', 'loanmax': '1111', 'summarizeby': 'State Level', 'allowcustomregion': True, 'state': [{'state': 'AK', '_uiSelectChoiceDisabled': False}, {'state': 'AZ', '_uiSelectChoiceDisabled': False}], 'county': [], 'citytown': [], 'zipcode': [], 'censustract': [], 'year': ['2021', '2022'], 'period': ['Q1', 'Q3'], 'reporting': {}, 'lt': {'lendertypesetup': True}}",
      },
      {
        q_id: 9,
        usr_id: 1,
        q_name: "rahul",
        q_create_ts: "2023-01-09 05:01:39.073148",
        q_lastmod: "2023-01-09 05:01:39.073160",
        q_parms:
          "{'reporttype': 'Ranking Report', 'reportrank': '# Loans', 'reportformat': 'HTML', 'brokerlenderbypass': False, 'customregion': False, 'excl_usahud': False, 'loantypessubbypass': False, 'refionly': False, 'isdaterange': False, 'daterange': {}, 'usecode': {'id': 3, 'usecodegroup': 'RES', 'usecode': '1-Family', '_uiSelectChoiceDisabled': False}, 'loanpurpose': 'Purchase', 'lendertype': 'Banks', 'lenderstodisplay': '5', 'lenders': [], 'ltext': '', 'loantypes': 'Conforming', 'loantypessub': ['Home Equity Loan'], 'loanmin': '1', 'loanmax': '1111', 'summarizeby': 'State Level', 'allowcustomregion': True, 'state': [{'state': 'AK', '_uiSelectChoiceDisabled': False}, {'state': 'AZ', '_uiSelectChoiceDisabled': False}], 'county': [], 'citytown': [], 'zipcode': [], 'censustract': [], 'year': ['2021', '2022'], 'period': ['Q1', 'Q3'], 'reporting': {}, 'lt': {'lendertypesetup': True}}",
      },
      {
        q_id: 10,
        usr_id: 1,
        q_name: "rahul",
        q_create_ts: "2023-01-09 05:01:55.217936",
        q_lastmod: "2023-01-09 05:01:55.217948",
        q_parms:
          "{'reporttype': 'Ranking Report', 'reportrank': '# Loans', 'reportformat': 'HTML', 'brokerlenderbypass': False, 'customregion': False, 'excl_usahud': False, 'loantypessubbypass': False, 'refionly': False, 'isdaterange': False, 'daterange': {}, 'usecode': {'id': 3, 'usecodegroup': 'RES', 'usecode': '1-Family', '_uiSelectChoiceDisabled': False}, 'loanpurpose': 'Purchase', 'lendertype': 'Banks', 'lenderstodisplay': '5', 'lenders': [], 'ltext': '', 'loantypes': 'Conforming', 'loantypessub': ['Home Equity Loan'], 'loanmin': '1', 'loanmax': '1111', 'summarizeby': 'State Level', 'allowcustomregion': True, 'state': [{'state': 'AK', '_uiSelectChoiceDisabled': False}, {'state': 'AZ', '_uiSelectChoiceDisabled': False}], 'county': [], 'citytown': [], 'zipcode': [], 'censustract': [], 'year': ['2021', '2022'], 'period': ['Q1', 'Q3'], 'reporting': {}, 'lt': {'lendertypesetup': True}}",
      },
      {
        q_id: 11,
        usr_id: 1,
        q_name: "rahul",
        q_create_ts: "2023-01-09 05:03:34.183392",
        q_lastmod: "2023-01-09 05:03:34.183405",
        q_parms:
          "{'reporttype': 'Ranking Report', 'reportrank': '# Loans', 'reportformat': 'HTML', 'brokerlenderbypass': False, 'customregion': False, 'excl_usahud': False, 'loantypessubbypass': False, 'refionly': False, 'isdaterange': False, 'daterange': {}, 'usecode': {'id': 3, 'usecodegroup': 'RES', 'usecode': '1-Family', '_uiSelectChoiceDisabled': False}, 'loanpurpose': 'Purchase', 'lendertype': 'Banks', 'lenderstodisplay': '5', 'lenders': [], 'ltext': '', 'loantypes': 'Conforming', 'loantypessub': ['Home Equity Loan'], 'loanmin': '1', 'loanmax': '1111', 'summarizeby': 'State Level', 'allowcustomregion': True, 'state': [{'state': 'AK', '_uiSelectChoiceDisabled': False}, {'state': 'AZ', '_uiSelectChoiceDisabled': False}], 'county': [], 'citytown': [], 'zipcode': [], 'censustract': [], 'year': ['2021', '2022'], 'period': ['Q1', 'Q3'], 'reporting': {}, 'lt': {'lendertypesetup': True}}",
      },
      {
        q_id: 12,
        usr_id: 1,
        q_name: "rk1",
        q_create_ts: "2023-01-09 05:40:10.528262",
        q_lastmod: "2023-01-09 05:40:10.528275",
        q_parms:
          "{'reporttype': 'Ranking Report', 'reportrank': '# Loans', 'reportformat': 'HTML', 'brokerlenderbypass': False, 'customregion': False, 'excl_usahud': False, 'loantypessubbypass': False, 'refionly': False, 'isdaterange': False, 'daterange': {}, 'usecode': {'id': 3, 'usecodegroup': 'RES', 'usecode': '1-Family', '_uiSelectChoiceDisabled': False}, 'loanpurpose': 'Purchase', 'lendertype': 'Banks', 'lenderstodisplay': '5', 'lenders': [], 'ltext': '', 'loantypes': 'Conforming', 'loantypessub': ['Home Equity Loan'], 'loanmin': '1', 'loanmax': '1111', 'summarizeby': 'State Level', 'allowcustomregion': True, 'state': [{'state': 'AK', '_uiSelectChoiceDisabled': False}, {'state': 'AZ', '_uiSelectChoiceDisabled': False}], 'county': [], 'citytown': [], 'zipcode': [], 'censustract': [], 'year': ['2021', '2022'], 'period': ['Q1', 'Q3'], 'reporting': {}, 'lt': {'lendertypesetup': True}}",
      },
      {
        q_id: 13,
        usr_id: 1,
        q_name: "rk1",
        q_create_ts: "2023-01-09 11:14:29.173083",
        q_lastmod: "2023-01-09 11:14:29.173095",
        q_parms:
          "{'reporttype': 'Ranking Report', 'reportrank': '# Loans', 'reportformat': 'HTML', 'brokerlenderbypass': False, 'customregion': False, 'excl_usahud': False, 'loantypessubbypass': False, 'refionly': False, 'isdaterange': False, 'daterange': {}, 'usecode': {'id': 3, 'usecodegroup': 'RES', 'usecode': '1-Family', '_uiSelectChoiceDisabled': False}, 'loanpurpose': 'Purchase', 'lendertype': 'Banks', 'lenderstodisplay': '5', 'lenders': [], 'ltext': '', 'loantypes': 'Conforming', 'loantypessub': ['Home Equity Loan'], 'loanmin': '1', 'loanmax': '1111', 'summarizeby': 'State Level', 'allowcustomregion': True, 'state': [{'state': 'AK', '_uiSelectChoiceDisabled': False}, {'state': 'AZ', '_uiSelectChoiceDisabled': False}], 'county': [], 'citytown': [], 'zipcode': [], 'censustract': [], 'year': ['2021', '2022'], 'period': ['Q1', 'Q3'], 'reporting': {}, 'lt': {'lendertypesetup': True}}",
      },
    ],
  },
};