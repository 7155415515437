import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

/**
 * All (Account) api End Points
 */
const getToken = () => localStorage.getItem("access_token");
export const accountActionApi = createApi({
  reducerPath: "accountActionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://devmodules.thewarrengroup.com/na_lo", // /login
  }),
  tagTypes: ["account", "get_user"],
  endpoints: (builder) => ({
    profileUpdate: builder.mutation({
      query: (body) => ({
        url: "profile_update",
        method: "POST",
        body,
      }),
      invalidatesTags: ["account"],
    }),
    getProfileUpdate: builder.query({
      query: (body) => ({
        url: "user_details",
        method: "POST",
        body,
      }),
      providesTags: ["account"],
    }),
    sso: builder.mutation({
      query: (body) => ({
        url: "/sso",
        method: "POST",
        body,
        headers: { authorization: `Bearer ${body?.sso_token}` },
      }),
      invalidatesTags: ["account"],
    }),
    login: builder.mutation({
      query: (body) => ({
        url: "/login",
        method: "POST",
        body,
      }),
      invalidatesTags: ["account"],
    }),
    getProfile: builder.mutation({
      query: (body) => ({
        url: "/profile",
        method: "GET",
        body,
        cache: "no-cache",
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      }),
    }),
    getQueryDetail: builder.query({
      query: (nmlsId) => `/nmls/${nmlsId}`
    }),
    createUser: builder.mutation({
      query: (body) => ({
        url: "/signup",
        method: "POST",
        body,
        cache: "no-cache",
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      }),
    }),
    updateUser: builder.mutation({
      query: (body) => ({
        url: `/user/${body.usr_id}`,
        method: "POST",
        body,
        cache: "no-cache",
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      }),
    }),
    getUser: builder.query({
      query: (body) => ({
        url: "/user",
        method: "GET",
        body,
        cache: "no-cache",
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      }),
    }),
  }),
});
