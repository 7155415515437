import { useField } from "formik";
import React, { useState, useEffect } from "react";
import {
  AsyncPaginate,
  reduceGroupedOptions,
} from "react-select-async-paginate";
import { loadSelectOptions } from "../../common/loadSelectOptions";

const filterByLabel = (arr = [], perm = []) => {
    return arr.filter((item) => perm.includes(item.label))
  };

  const filterByGroupOptions = (arr = [], labelCheck = [], valueCheck = []) => {
    let results = arr.filter((item) => labelCheck.includes('All') ? !labelCheck.includes(item.label) : labelCheck.includes(item.label));
    results = labelCheck.includes('All') ? results : results.map((item) => ({
      ...item,
      options: item.options.filter((opt) => valueCheck.includes(opt.label)),
    }));
    return results;
  };

export default function AsyncSelectPaginateOptionsQueryForm({ selectedCountyList, townList, usr_citytown_permission, usr_county_permission, isDisableField, ...rest }) {
  const name = rest.name || "multipleSelect";
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const { touched, error } = meta;
  const [selectedValue, setSelectedValue] = useState(null);
  const [optionList, setOptionList] = useState([]);
  const [menuOpenCheck, setMenuOpenCheck] = useState(false);
  useEffect(()=>{
    setOptionList(townList)
  },[townList]);

  useEffect(()=>{
    if(isDisableField){
      setValue([]);
    }
  }, [isDisableField])

  useEffect(()=>{
    if(Array.isArray(field?.value) && field?.value?.length > 0){
      setSelectedValue(field?.value)
    }
  },[field]);

  useEffect(()=>{
    var valList = selectedValue?.map(x=>x.value)
    if(Array.isArray(selectedValue) && selectedValue.length > 0 && valList.includes('All') && field.name !== "state"){
      let data_one = townList?.filter(x => x?.label !== 'All')
      let data_two = data_one?.map(y => {
        let p = y?.options?.map(p => ({...p, isDisabled: true}));
        return {
          label: y?.label,
          options: p
        }
      })
      setOptionList(data_two)
    }
    
    else if(Array.isArray(selectedValue) && selectedValue.length > 0 && !valList.includes('All') && field.name !== "state") {
      let data_one = townList;
      let data_two = data_one?.map(y => {
        let p = y?.options?.map(p => ({...p, isDisabled: false}));
        return {
          label: y?.label,
          options: p
        }
      })
      setOptionList(data_two)
    }

    else if(Array.isArray(selectedValue) && selectedValue.length === 0 && field.name !== "state") {
      let data_one = townList;
      let data_two = data_one?.map(y => {
        let p = y?.options?.map(p => ({...p, isDisabled: false}));
        return {
          label: y?.label,
          options: p
        }
      })
      setOptionList(data_two)
    }
  },[selectedValue]);

  const loadOptionsWrapper = async (q, prevOptions, { page }) => {
  const { options, hasMore } = await loadSelectOptions(q, page, selectedCountyList.includes('All') && usr_citytown_permission.includes('All') ? optionList : 
  filterByGroupOptions(optionList, usr_county_permission, usr_citytown_permission));
  return {
    options,
    hasMore,
    additional: {
      page: page + 1
    }
  };
};

const defaultAdditional = {
    page: 1
  };

  return (
    <>
      {!menuOpenCheck && <AsyncPaginate
        {...field}
        additional={defaultAdditional}
        onChange={(val)=>{setValue(val); setSelectedValue(val)}}
        loadOptions={loadOptionsWrapper}
        reduceOptions={reduceGroupedOptions}
        cacheUniqs={[selectedCountyList]}
        isMulti
        closeOnSelect={false}
        closeMenuOnSelect={false}
        onSelectResetsInput={false}
        onMenuOpen={()=>setMenuOpenCheck(true)}
        onMenuClose={()=>setMenuOpenCheck(false)}
        {...rest}
      />}
      {menuOpenCheck && <AsyncPaginate
        {...field}
        additional={defaultAdditional}
        onChange={(val)=>{setValue(val); setSelectedValue(val)}}
        loadOptions={loadOptionsWrapper}
        reduceOptions={reduceGroupedOptions}
        isMulti
        closeOnSelect={false}
        closeMenuOnSelect={false}
        onSelectResetsInput={false}
        onMenuOpen={()=>setMenuOpenCheck(true)}
        onMenuClose={()=>setMenuOpenCheck(false)}
        {...rest}
      />}
      {touched && error ? <span className="text-danger">{error}</span> : null}
    </>
  );
}
