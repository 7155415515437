import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateRange = ({ date, setDate, isDisabled }) => {
  // const [startDate, setStartDate] = useState();
  // const [endDate, setEndDate] = useState();
  const { startDate, endDate } = date;
  return (
    <>
      <DatePicker
        placeholderText="Start date"
        className="mb-2"
        dateFormat="MM/dd/yyyy"
        selected={startDate}
        onChange={(date) => setDate("startDate", date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        disabled={isDisabled}
      />
      <DatePicker
        placeholderText="End date"
        dateFormat="MM/dd/yyyy"
        className="mb-2"
        selected={endDate}
        onChange={(date) => setDate("endDate", date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        disabled={isDisabled}
      />
    </>
  );
};
export default DateRange;
