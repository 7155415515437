import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";

export default function AuthRedirect() {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("access_token");
  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else if (location.pathname === "/" || location.pathname === "/login") {
      navigate("/home");
    }
  }, []);

  return <Outlet />;
}
